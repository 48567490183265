import React, { useEffect, useState } from 'react';
import { logInDevelopment } from '../../utils';
import config from '../../config';

const ProductDropDown = ({ product, userFromApi, apiData }) => {

  const handleDropdownItemClick = (productName, redirectUrl) => {
    const newUrl = `${window.location.origin}${window.location.pathname}?${redirectUrl}`;
    window.location.assign(newUrl); // This will update the URL and refresh the page
  };

  const filteredProducts = apiData ? apiData.filter(p => p.productName !== product) : [];

  return (
    <div className="item">
     {/*  <div className="d-flex gap-2 align-items-center justify-content-between flex-wrap">
        <h6 className="page-subtitle">Product : </h6>
        <div className="d-flex gap-2">
          <div className="dropdown input-dropdown">
            <div 
              className="dropdown-toggle" 
              type="button" 
              id="dropdownMenuButton1" 
              data-bs-toggle="dropdown" 
              aria-expanded="false">
              {product}
            </div>
            <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
            {filteredProducts.length > 0 ? (
                filteredProducts.map((p, index) => (
                  <li key={index}>
                    <a className="dropdown-item" href="javascript:void(0)" onClick={() => handleDropdownItemClick(p.productName, p.redirectUrl)}>{p.productName}</a>
                  </li>
                ))
              ) : (
                <li>
                  <a className="dropdown-item" href="javascript:void(0)">No other products available</a>
                </li>
              )}
            </ul>
          </div>
          
          <div className="dropdown input-dropdown">
            <div 
              className="dropdown-toggle" 
              type="button" 
              id="dropdownMenuButton1" 
              data-bs-toggle="dropdown" 
              aria-expanded="false">
              All
            </div>
            {logInDevelopment('apiData = :', apiData)}
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><a className="dropdown-item" href="javascript:void(0)">Active</a></li>
              <li><a className="dropdown-item" href="javascript:void(0)">Free</a></li>
              <li><a className="dropdown-item" href="javascript:void(0)">Inactive</a></li>
            </ul>
          </div>
          
        </div>
      </div>*/}
    </div>
  );
};

export default ProductDropDown;