/**
 *  src/utils.js
 * 
 * Develper : Ravinesh Raj
 *  
 * @param {*} message 
 * @param  {...any} optionalParams 
 */
const logInDevelopment = (message, ...optionalParams) => {
    if (process.env.REACT_APP_STAGE === 'development') {
      console.log(message, ...optionalParams);
    }
  };

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
};

const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + (days*24*60*60*1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
};
  
  export { logInDevelopment, getCookie,  setCookie};
  