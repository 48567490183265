import React from 'react';

const Footer = () => {
    return (
<footer className="footer py-5 mt-4">
    <div className="container">
        <div className="row gap-4 gap-md-0">
            <div className="col-lg-3 text-center">
                <a className="navbar-brand" href="https://www.appypie.com/endpoint">
                     <img src={`${process.env.PUBLIC_URL}/appypie-endpoint.svg`} height="30" alt="AppyPie Endpoint"  title="AppyPie Endpoint"/>
                </a>
            </div>
            <div className="col-lg-6">
                <ul className="footer-list">
                    <li><a href="https://www.appypie.com/about-us">About Us</a></li>
                    <li><a href="https://www.appypie.com/security">Terms of Use</a></li>
                    <li><a href="https://www.appypie.com/privacy-policy">Privacy Policy</a></li>
                    <li><a href="https://careers.appypie.com/careers">Career</a></li>
                    <li><a href="https://www.appypie.com/google-api-disclosure">Google Disclosure</a></li>
                </ul>
            </div>
            <div className="col-lg-3">
                <ul className="footer-social-links">
                    <li className="facebook"><a href="https://www.facebook.com/AppyPieInc">Facebook</a></li>
                    <li className="twitter"><a href="https://x.com/AppyPieInc">twitter</a></li>
                    <li className="pinterest"><a href="https://www.pinterest.com/appypie/">pinterest</a></li>
                    <li className="blogger"><a href="https://www.appypie.com/blog/">blogger</a></li>
                    <li className="linkedin"><a href="https://www.linkedin.com/company/appy-pie-inc">linkedin</a></li>
                    <li className="youtube"><a href="https://www.youtube.com/@AppyPieInc">youtube</a></li>
                    <li className="instagram"><a href="https://www.instagram.com/AppyPieInc/">instagram</a></li>
                </ul>
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-12 text-center">
                <p className="copyright-text">This website is powered by Appy Pie's Website Builder and the App Maker is powered by Appy Pie's App Builder © 2024. All rights reserved. The technology is protected under US Patent No. PCT/US21/36182.</p>
            </div>
        </div>
    </div>
</footer>
);
};
export default Footer;